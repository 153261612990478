import logo from "../assets/logo.png"

import "boxicons";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

function Header(props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen((open) => !open);
  };

  const navigate = useNavigate();

  return (
    <header className={`header ${isOpen ? "is-open" : ""}`}>
      <div className="header-nav-top">
        <div className="mobile-header-wrap">
          <a href="/">
            <img src={logo} alt="Scelsi Law, LLC logo" className="logo" />
          </a>
        </div>

        <div className="hamburger-wrapper hide-me" onClick={toggleMenu}>
          <a className={`hamburger ${isOpen ? "hide-me" : ""}`}>
            <box-icon name="menu" color="var(--logo-secondary)"></box-icon>
          </a>
          <a className={`hamburger ${isOpen ? "show-me" : "hide-me"} `}>
            <box-icon name="x" color="var(--logo-secondary)"></box-icon>
          </a>
        </div>

      </div>

      <div className="header-nav-bottom">
        <nav className={`header-nav ${isOpen ? "is-open" : ""}`}>
          <NavLink to="/about" className="header-text">
            <p>About Me</p>
          </NavLink>

          {/* <NavLink to="/howwedoit" className="header-text"> */}
            <div className="phone-icon header-text">
              <box-icon type='solid' name='phone' color="var(--logo-secondary)"></box-icon>
              <a href="tel:+7248044273"
              >724-804-4273</a>
            </div>
          {/* </NavLink> */}
        </nav>
      </div>
    </header>
  );
}

export default Header;
