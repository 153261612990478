import timHeadshot from "../assets/tim_headshot.jpg";

import axios from "axios";
import { useEffect, useState } from "react";

function Home(props) {
  const [contactState, setContactState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    message: "",
  });

  const contactForm = async (e) => {
    // e.preventDefault();
    //todo: this isn't the react way to access the form elements but that's what the problem was.
    let form = {
      firstname: e.currentTarget.elements.firstname.value,
      lastname: e.currentTarget.elements.lastname.value,
      email: e.currentTarget.elements.email.value,
      phone: e.currentTarget.elements.phone.value,
      message: e.currentTarget.elements.message.value,
    };
    //form elements are found in e.currentTarget.elements
    //console.log(e.currentTarget.elements);
    //I tried setting state here and that didn't work
    //setContactState(e.currentTarget.elements);
    console.log("Contact Form Submitted!");
    console.log("-----Contact State-----");
    console.log(contactState);
    let domain = "localhost:3001";
    let protocol = window.location.protocol;

    try {
      //const res = await axios.post(domain + '/api/contact', contactState)
      //using form until we figure out why setState is broken

      //this isn't the prettiest way to handle
      document.getElementById("contact-wrapper").innerHTML =
        "<h2 style='padding-bottom:30px;'>Thank you! I'll be in touch soon.</h2>";
      //const res = await axios.post(protocol + domain + '/api/contact', form)
      const res = await axios.post("/api/contact", form);

      setContactState({
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        message: "",
      });

      return false;
    } catch (err) {
      if (err.code === 500) {
        console.error(err);
      }
    }
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="main">
          <h1 className="main-h1">Timothy J. Scelsi, Esq.</h1>
          <div className="main-h4-image-div">
            <div className="main-image-div">
              <img
                src={timHeadshot}
                alt="Headshot of Tim Scelsi"
                className="main-image"
                width={"300px"}
              ></img>
              <h4 className="main-h4">
                Tim Scelsi has been a resident of Westmoreland County,
                Pennsylvania, for more than a decade. During that time, he has
                provided representation to individuals involved in litigation.
                His practice has included representing both Plaintiffs and
                Defendants, which has provided a unique perspective in preparing
                a case for trial. Throughout his career, Attorney Scelsi has
                represented countless individuals at hearings, depositions,
                arbitrations, and at trials, in matters covering a wide range of
                topics, including car accidents, wrongful death actions, dog
                bites, slip-and-falls, and premise liability claims. In 2023,
                Attorney Scelsi was invited to join the Westmoreland Academy of
                Trial Lawyers, which is a select group of attorneys recognized
                by their peers as the premier civil trial lawyers in
                Westmoreland County.
              </h4>
            </div>
          </div>
          <div className="main-button-div">
            <a href="#contact-wrapper">
              <button className="main-button" id="consultation">
                Get your free consultation!
              </button>
            </a>
          </div>
        </div>
      </div>

      <div id="contact-wrapper">
        <h2>Contact Us</h2>
        <form onSubmit={contactForm} method="POST">
          <div className="first-last-name">
            <div className="firstname">
              <label htmlFor="firstname">First Name * </label>
              <input
                type="text"
                id="firstname"
                className="contact-input"
                name="firstname"
                required
              ></input>
            </div>

            <div className="lastname">
              <label htmlFor="lastname">Last Name * </label>
              <input
                type="text"
                id="lastname"
                className="contact-input"
                name="lastname"
                required
              ></input>
            </div>
          </div>
          <div className="email-phone">
            <div className="email">
              <label htmlFor="email">Email * </label>
              <input
                type="email"
                id="email"
                className="contact-input"
                name="email"
                required
              ></input>
            </div>

            <div className="phone">
              <label htmlFor="phone">Phone</label>
              <input
                type="tel"
                id="phone"
                className="contact-input"
                name="phone"
              ></input>
            </div>
          </div>
          <div className="message">
            <label htmlFor="message">Message *</label>
            <textarea
              type="text"
              id="message"
              name="message"
              className="contact-input"
              rows={"10"}
              required
            ></textarea>
          </div>

          <div className="submit-btn-div">
            <div className="terms-div">
              <input
                type="checkbox"
                name="terms"
                id="terms"
                className="contact-input"
                required
              ></input>
              <label htmlFor="terms">
                I understand that submitting this inquiry or contact request
                does not create an attorney-client relationship.
              </label>
            </div>
            <div
              className="g-recaptcha"
              id="html_element"
              data-sitekey="6LdmtCApAAAAADjXlkdXJsgHqWpdNWYV6mSHSmNQ"
            ></div>
            <br />
            <button type="submit" className="main-button" value={"Submit"}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default Home;
