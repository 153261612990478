function AboutMe(props) {


    return (
      
      <div className="about-me-wrapper">

        <h1 className="main-h1">Timothy J. Scelsi, Esq.</h1>

        <div className="about-me-body">

          <div className="about-me-paragraphs">
            <p>Attorney Scelsi graduated magna cum laude from Slippery Rock University of Pennsylvania in 2010, where he majored in Political Science with a double minor in Philosophy and Spanish.  He then went on to Duquesne University School of Law where he earned his Juris Doctor, graduating cum laude in 2013.  During his first year of legal studies, Attorney Scelsi earned an Outstanding Oral Advocate designation for the First Year Appellate Oral Argument Exercise, and the written brief in support of that argument also earned Mr. Scelsi the commendation of “Best Brief,” as well as an Excellence in Legal Writing Award for 2011.  While at Duquesne, Attorney Scelsi received the C.A.L.I. Excellence for the Future Award for the highest grades in Energy Law, Estate Planning, and Core Competencies II.</p>

            <p>Attorney Scelsi was also a member of Duquesne’s National Trial Team and competed in both the 2011-2012 American College of Trial Lawyers National Trial Competition, in which he was a semifinalist, and the 2012-2013 Tournament of Champions.  His performance in trial advocacy earned him membership into the Louis L. Manderino Honor Society for distinguished achievement in moot court competition.</p>
	
            <p>Attorney Scelsi is admitted to practice before the Courts of the Commonwealth of Pennsylvania, and the United States District Court for Western Pennsylvania; he is also a member of the Westmoreland Bar Association and the Pennsylvania Bar Association.</p>
          </div>

          <div className="about-me-details-wrapper-v">
            <div className="about-me-details-v">
              <h2>Areas of Practice</h2>
              <ul className="one-about-me-detail-wrapper">
                <li>Personal Injury</li>
                <li>Wrongful Death</li>
                <li>Automobile Accidents</li>
              </ul>
            </div>

            <div className="about-me-details-v">
              <h2>Litigation Percentage</h2>
              <ul className="one-about-me-detail-wrapper">
                <li>80% of Practice Devoted to Litigation</li>
              </ul>
            </div>

            <div className="about-me-details-v">
              <h2>Languages</h2>
              <ul className="one-about-me-detail-wrapper">
                <li>English</li>
                <li>Spanish</li>
              </ul>
            </div>

          </div>        

        </div>

        <div className="about-me-details-wrapper-h">
          
        <div className="about-me-details-h">
              <h2>Bar Admissions</h2>
              <ul className="one-about-me-detail-wrapper">
                <li>United State District Court for the Western District of Pennsylvania, 2019</li>
                <li>Supreme Court of Pennsylvania, 2013</li>
              </ul>
            </div>

          <div className="about-me-details-h">
              <h2>Education</h2>
              <ul className="one-about-me-detail-wrapper">
                <li className="strong">Duquesne University School of Law, Pittsburgh, Pennsylvania</li>
                <ul>
                  <li className="indent padding-bottom">J.D. <em>cum laude</em> - 2013</li>
                </ul>
                <li className="strong">Slippery Rock University of Pennsylvania, Slippery Rock, Pennsylvania</li>
                <ul>
                  <li className="indent">B.S. <em>magna cum laude</em> - 2010</li>
                  <li className="indent">Major: Political Science</li>
                  <li className="indent">Minor: Philosophy and Spanish</li>
                </ul>
              </ul>
            </div>

            <div className="about-me-details-h">
              <h2>Honors and Awards</h2>
              <ul className="one-about-me-detail-wrapper">
                <li>Outstanding Oral Advocate</li>
                <li>Excellence in Legal Writing Award</li>
                <li>C.A.L.I. Excellence for the Future Award: Estate Planning, Core Competencies II, and Energy Law</li>
                <li>National Institute for Trial Advocacy, Tournament of Champions, 2013</li>
                <li>American College of Trial Lawyers, National Trial Competition Regional Semi-Finalist, 2012</li>
                <li>Recipient of Excellence in Legal Writing Award, 2011</li>
                <li>Member of Louis L. Manderino Honor Society </li>
              </ul>
            </div>

            <div className="about-me-details-h">
              <h2>Professional Associations and Memberships</h2>
              <ul className="one-about-me-detail-wrapper">
                <li>Westmoreland Academy of Trial Lawyers 2023 - Present</li>
                <li>Westmoreland Bar Association, Member, 2013 - Present</li>
                <li>Pennsylvania Bar Association, Member, 2013 - Present</li>
              </ul>
            </div>
        
          </div> 

    </div>

     
    )

};

export default AboutMe;