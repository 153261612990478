import "boxicons";

function Footer(props) {
  return (
    <footer className="footer">
      <div className="footer-nav-wrapper">
        <nav className="footer-nav-1">
          {/* <a href="/about">
            <p>About Me</p>
          </a> */}

          <div className="hours-div">
            <box-icon
              name="time-five"
              type="solid"
              color="var(--logo-secondary"
            ></box-icon>
            <p>Monday - Friday: 9AM - 5PM</p>
          </div>

          <div className="location-div">
            <a
              href="http://maps.google.com/?q=400 Main Street Latrobe, Pennsylvania 15650"
              target="_blank"
            >
              400 Main Street Latrobe, Pennsylvania 15650
              <box-icon
                type="solid"
                name="map-pin"
                color="var(--logo-secondary)"
              ></box-icon>
            </a>
          </div>
        </nav>

        <nav className="footer-nav-2">
          {/* <a href="/" target="_blank">
            <box-icon
              type="logo"
              name="linkedin-square"
              color="var(--logo-secondary)"
            ></box-icon>
          </a> */}

          <div className="phone-div">
            <a href="tel:+7248044273">
              724-804-4273
              <box-icon
                type="solid"
                name="phone"
                color="var(--logo-secondary)"
              ></box-icon>
            </a>
          </div>

          <div className="email-div">
            <a href="mailto:tjs@scelsilaw.net">
              tjs@scelsilaw.net
              <box-icon
                type="solid"
                name="envelope"
                color="var(--logo-secondary)"
              ></box-icon>
            </a>
          </div>
        </nav>
      </div>
      <div className="copyright">
        <p>&copy; 2023 Scelsi Law, LLC</p>
      </div>
    </footer>
  );
}

export default Footer;
